import "core-js/modules/es.date.to-string"; /*
                                             * @Description:
                                             * @Version: 1.0
                                             * @Autor: silencc
                                             * @Date: 2020-11-13 14:58:21
                                             * @LastEditors: silencc
                                             * @LastEditTime: 2020-11-16 14:34:54
                                             */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '积分变更时间',
    kname: 'createAt' },

  {
    label: '变更原因',
    kname: 'note' },

  {
    label: '积分',
    kname: 'integral' }];};




export var searchOption = function searchOption(ctx) {return [
  {
    kname: 'startTime',
    label: '开始时间：',
    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        valueFormat: 'yyyy-MM-dd 00:00:00',
        pickerOptions: {
          disabledDate: function disabledDate(v) {
            if (v.valueOf() > new Date(new Date().toLocaleDateString()).valueOf()) {
              return true;
            }
            return false;
          } } } } },




  {
    kname: 'endTime',
    label: '结束时间：',
    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        valueFormat: 'yyyy-MM-dd 23:59:59',
        pickerOptions: {
          disabledDate: ctx.showEndTime } } } }];};